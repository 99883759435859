// REDESIGN 2024

// !!! import * as Sentry from '@sentry/browser';
// sentry je zatím zakomentované - build procházel, i když nebylo nainstalované @sentry/browser, což rozbíjelo produkci (přestávalo fungovat jQuery)

const navigationInit = async () => {
    const navigationElement: HTMLElement | null = document.querySelector('[data-navigation]');

    if (navigationElement) {
        const { navigation } = await import(
            /* webpackChunkName: 'chunk-navigation' */
            './components/navigation/navigation.ts'
        );

        navigation(navigationElement);
    }
};

export const swiperInit = async () => {
    const swiperSelectors: NodeListOf<HTMLElement> = document.querySelectorAll('[data-swiper]');

    if (swiperSelectors.length > 0) {
        const { swiper } = await import(
            /* webpackChunkName: 'chunk-swiper' */
            './components/swiper.ts'
        );

        for (const swiperSelector of swiperSelectors) {
            if (swiperSelector instanceof HTMLDivElement) swiper(swiperSelector);
        }
    }
};

// Součást navigace
const dynamicNavigationInit = async () => {
    const dynamicNavigationTemplate: HTMLTemplateElement | null = document.querySelector(
        '[data-dynamic-navigation-template]',
    );

    if (dynamicNavigationTemplate) {
        const { dynamicNavigation } = await import(
            /* webpackChunkName: 'chunk-dynamicNavigation' */
            './components/navigation/dynamicNavigation.ts'
        );

        dynamicNavigation(dynamicNavigationTemplate);

        swiperInit();
    }
};

// Součást navigace
const infoStripInit = async () => {
    const infoStripElement: HTMLElement | null = document.querySelector('[data-info-strip]');

    if (infoStripElement) {
        const { infoStrip } = await import(
            /* webpackChunkName: 'chunk-infoStrip' */
            './components/navigation/infoStrip.ts'
        );
        infoStrip({
            infoStripElement,
        });
    }
};

const breakingNewsInit = async () => {
    const breakingNewsTemplate: HTMLTemplateElement | null = document.querySelector(
        '[data-breaking-news-template]',
    );

    if (breakingNewsTemplate) {
        const { breakingNews } = await import(
            /* webpackChunkName: 'chunk-breakingNews' */
            './components/navigation/breakingNews/breakingNews.ts'
        );

        breakingNews(breakingNewsTemplate);
    }
};

const giftsAIInit = async () => {
    const giftsAIFormElement: HTMLFormElement | null =
        document.querySelector('[data-gifts-ai-form]');

    if (giftsAIFormElement) {
        const { giftsAI } = await import(
            /* webpackChunkName: 'chunk-giftsAI' */
            './components/giftsAI/giftsAi.ts'
        );
        giftsAI({ form: giftsAIFormElement });
    }
};

const atomicDesignInit = async () => {
    const articleGalleryBigElement: HTMLElement | null =
        document.querySelector('[data-atomic-design]');

    if (articleGalleryBigElement) {
        const { atomicDesign } = await import(
            /* webpackChunkName: 'chunk-atomicDesign' */
            './modules/atomic-design/atomicDesign.ts'
        );

        atomicDesign();
    }
};

const contentExpanderInit = async () => {
    const contentExpanderElements: NodeListOf<HTMLElement> =
        document.querySelectorAll('[data-content-expander]');

    if (contentExpanderElements.length > 0) {
        const { contentExpander } = await import(
            /* webpackChunkName: 'chunk-contentExpander' */
            './components/contentExpander.ts'
        );

        contentExpander(contentExpanderElements);
    }
};

const formTextareaInit = async () => {
    const textAreaWrappers: NodeListOf<HTMLDivElement> =
        document.querySelectorAll('[data-form-textarea]');

    if (textAreaWrappers) {
        const { formTextarea } = await import(
            /* webpackChunkName: 'chunk-formTextarea' */
            './element/form/formTextarea.ts'
        );

        formTextarea({ textAreaWrappers });
    }
};

const tooltipInit = async () => {
    const tooltipElements: NodeListOf<HTMLElement> = document.querySelectorAll('[data-tooltip]');

    if (tooltipElements.length > 0) {
        const { tooltip } = await import(
            /* webpackChunkName: 'chunk-tooltip' */
            './element/tooltip.ts'
        );

        tooltip(tooltipElements);
    }
};

Promise.all([
    navigationInit(),
    dynamicNavigationInit(),
    infoStripInit(),
    atomicDesignInit(),
    breakingNewsInit(),
    swiperInit(),
    contentExpanderInit(),
    giftsAIInit(),
    formTextareaInit(),
    tooltipInit(),
]).then(
    () => {
        // console.log('Všechny moduly načteny.');
    },
    () => {
        // console.log('Some modules not loaded');
    },
);
